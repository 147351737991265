import React, { useContext, useEffect, useState } from "react"
import { LoadingView } from "../utils/components"
import DashboardPageContainer from "./components/DashboardPageContainer"
import { updateObjectState, useOffCanvas } from "../utils/functions"
import { AppContext } from "../utils/components/AppContext"
import { useNavigate, useParams } from "react-router-dom"
import OrganizationStructureCard from "./components/Organization/OrganizationStructureCard"
import { Button, Card, Col, Row } from "react-bootstrap"
import { OffCanvasContext } from "../utils/components/OffCanvasContext"
import PageMetaTags from "./components/Shared/PageMetaTags"
import EmployeeSearch from "./components/EmployeeSearch"
import { FaSearch } from "react-icons/fa"
import OrganizationSearch from "./components/Organization/OrganizationSearch"

function OrganizationStructure({
  getRequest,
  postRequest,
  putRequest,
  patchRequest,
}) {
  const { contextState, updateContextState } = useContext(AppContext)
  const { permissions } = contextState
  const { showOffCanvas } = useOffCanvas()
  const { closeOffCanvas, restoreOffCanvas } = useContext(OffCanvasContext)

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [currentOrg, setCurrentOrg] = useState({})
  const [allOrgs, setAllOrgs] = useState({})

  const getOrgStructure = async (org_id = "none") => {
    if (allOrgs[org_id]) {
      setCurrentOrg(allOrgs[org_id])
      return
    }
    getRequest(
      `/organization/structure/${org_id}`,
      setIsLoading,
      (response) => {
        setCurrentOrg(response.organization_structure)
        const structure = response.organization_structure
        const orgs = { ...allOrgs }
        orgs[structure.organization.id] = response.organization_structure
        setAllOrgs(orgs)
      }
    )
  }

  useEffect(() => {
    getOrgStructure()
  }, [])

  return (
    <LoadingView
      isLoading={isLoading}
      view={
        <div className={"pt-4"}>
          <PageMetaTags title={`Organogram`} />
          <div className={"position-absolute end-0 pe-5 z-3"}>
            <Button
              variant={"outline-primary"}
              onClick={() => {
                showOffCanvas({
                  title: "Search Organizations",
                  subtitle:
                    "Search for an organization to view their organizational structure",
                  component: (
                    <div>
                      <OrganizationSearch
                        onResultClick={(organization) => {
                          getOrgStructure(organization.id)
                          closeOffCanvas()
                        }}
                      />
                    </div>
                  ),
                })
              }}
            >
              <FaSearch className={"me-2"} />
              Search
            </Button>
          </div>
          <div className={"mb-5"} />

          {Object.keys(currentOrg).length > 0 && (
            <div>
              {currentOrg.parents.map((parent, index) => {
                return (
                  <Col
                    xs={10}
                    lg={3}
                    key={index}
                    className="mx-auto mb-4"
                  >
                    <OrganizationStructureCard
                      organization={parent}
                      getStructure={(orgId) => {
                        getOrgStructure(orgId)
                      }}
                    />
                  </Col>
                )
              })}

              <Card
                body={true}
                className={
                  "border-0 border-top border-1 border-secondary mt-3 bg-transparent"
                }
              >
                <h4 className={"mt-4 ms-4 text-primary"}>
                  {currentOrg.organization.name} and Peer Organizations
                </h4>
                <Row className="justify-content-center mt-5">
                  {currentOrg.peers.map((peer, index) => {
                    return (
                      <Col
                        lg={3}
                        key={index}
                      >
                        <OrganizationStructureCard
                          organization={peer}
                          getStructure={(orgId) => {
                            getOrgStructure(orgId)
                          }}
                        />
                      </Col>
                    )
                  })}
                </Row>
              </Card>

              <Card
                body={true}
                className={
                  "border-0 border-top border-1 border-secondary mt-3 bg-transparent"
                }
              >
                <h4 className={"mt-4 ms-4 text-primary"}>
                  {currentOrg.organization.name}'s Child Organizations
                </h4>
                <Row className="justify-content-center mt-5">
                  {currentOrg.children.map((child, index) => {
                    return (
                      <Col
                        lg={3}
                        key={index}
                      >
                        <OrganizationStructureCard
                          organization={child}
                          getStructure={(orgId) => {
                            getOrgStructure(orgId)
                          }}
                        />
                      </Col>
                    )
                  })}
                </Row>
              </Card>
            </div>
          )}
        </div>
      }
    />
  )
}

function OrganizationStructurePage() {
  return <DashboardPageContainer PageComponent={OrganizationStructure} />
}

export default OrganizationStructurePage
