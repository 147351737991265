export const AppRoutes = {
  // pages
  Index: { path: "/" },
  Home: { path: "/home" },
  SignUp: { path: "/signup" },
  SignIn: { path: "/signin" },
  Registration: { path: "/registration" },
  Stats: { path: "/organization-statistics" },
  OrgChart: { path: "/organogram" },
  OrgStructure: { path: "/organization-structure" },
  Finance: { path: "/finance" },
  Payroll: { path: "/payroll" },
  People: { path: "/people" },
  Attendance: { path: "/attendance" },
  Appointments: { path: "/appointments" },
  Recruitment: { path: "/recruitment" },
  ManagersHub: { path: "/managers-hub" },
  TeamSupervisorView: {
    path: "/managers-hub/team/:teamId",
    paramLessPath: "/managers-hub/team/",
    mainNav: "managers-hub",
  },
  MyProfile: { path: "/my-profile" },
  Admin: { path: "/admin" },
  Employee: {
    path: "/people/employee/:employeeId",
    paramLessPath: "/people/employee/",
    mainNav: "people",
  },
  BulkAddEmployees: { path: "/people/bulk-add-employees" },
  TimeOff: {
    path: "/time-off",
  },
  TimeOffRequests: {
    path: "/time-off-requests",
  },
  TimeOffRequestsReview: {
    path: "/time-off-requests/review",
  },
  Schedule: {
    path: "/attendance/schedules/:scheduleId",
    paramLessPath: "/attendance/schedules/",
    mainNav: "Attendance",
  },
  TermsOfService: { path: "/terms-of-service" },
  PrivacyPolicy: { path: "/privacy-policy" },
  PasswordReset: { path: "/reset-password" },
  PasswordChange: { path: "/change-password" },
  SubscriptionExpired: { path: "/subscription-expired" },
  Staff: { path: "/staff" },
  StaffOrgView: {
    path: "/staff/organization/:organizationId",
    paramLessPath: "/staff/organization/",
  },
  Performance: { path: "/performance" },
  PerformanceReviews: { path: "/performance/reviews" },
  PerformanceReviewPeriod: {
    path: "/performance/review-period/:reviewPeriodId",
    paramLessPath: "/performance/review-period",
  },
  PerformanceReviewPeriodForm: {
    path: "/performance/review-period/form/:formId",
    paramLessPath: "/performance/review-period/form",
  },
  CompletePerformanceReview: {
    path: "/performance/review/complete/:reviewerId",
    paramLessPath: "/performance/review/complete",
  },
  ViewEmployeePerformanceReviews: {
    path: "/performance/review-form/:periodFormId/employee/:employeeId",
  },
  Accounting: { path: "/accounting" },
  PayPeriod: {
    path: "/payroll/pay-period/:payPeriodId",
    paramLessPath: "/payroll/pay-period",
  },
}
