import React, { useContext } from "react"
import { AddItemButton } from "../../../utils/components"
import {
  useAppModal,
  useAppModal2,
  useOffCanvas,
} from "../../../utils/functions"
import { AppContext } from "../../../utils/components/AppContext"
import { Button, Card, Tab, Table, Tabs } from "react-bootstrap"
import { dateFormats, formatDate } from "../../../utils/dateTime"
import { appConfigs } from "../../../configs"
import GenericForm from "../../Forms/Shared/GenericForm"
import { FormFieldTypes } from "../../FormFields"
import { EmpPermissions } from "../../../enums/EmpPermissions"
import { FaTrashAlt } from "react-icons/fa"

function AttendanceDevicesTab({
  devices,
  setDevices,
  permissions,
  putRequest,
  patchRequest,
  loaderCallback,
  configs,
}) {
  const { showOffCanvas } = useOffCanvas()
  const { updateContextState, hasEmpPermission } = useContext(AppContext)
  const { showAppModal, closeModal } = useAppModal()

  const assignToBranch = (item) => {
    updateContextState({
      formData: { device_code: item.device_code, device_id: item.id },
    })
    showOffCanvas({
      title: `Assign Device to Branch`,
      subtitle: `Assign this device to a branch`,
      component: (
        <>
          <GenericForm
            formConfigs={[
              {
                valueKey: "device_code",
                type: FormFieldTypes.TEXT,
                disabled: true,
              },
              {
                valueKey: "branch_id",
                labelName: "Branch to Assign Device to",
                type: FormFieldTypes.OBJECT_SELECT,
                optionsList: Object.values(configs.branches),
                optionsValueKey: "id",
                optionsLabelKey: "name",
                info: "Select the branch to assign this device to",
              },
              {
                valueKey: "device_name",
                type: FormFieldTypes.TEXT,
                info: "Enter the name of the device for easy identification e.g. Main Lobby",
              },
            ]}
            onSubmit={(data) => {
              putRequest(
                `/attendance/devices`,
                loaderCallback,
                (res) => {
                  setDevices(res.devices)
                },
                data
              )
            }}
          />
        </>
      ),
    })
  }

  return (
    <div>
      <Card className={"mb-4"}>
        <Card.Header className={"py-4"}>
          <Card.Title>Attendance Devices</Card.Title>
          <Card.Subtitle
            as={"p"}
            className={"mt-3"}
          >
            Manage attendance devices provisioned for your organization that
            employees can use to clock in / out of work.
            <br />
            Below are the unique authorization codes that you can enter in the{" "}
            {appConfigs.appName} Attendance app to authorize devices to use the
            app
          </Card.Subtitle>
        </Card.Header>
      </Card>

      <Tabs
        defaultActiveKey="used"
        id="attendance-devices-tabs"
      >
        {Object.entries(devices).map(([key, items]) => {
          const unUsedTab = key === "un_used"
          return (
            <Tab
              eventKey={key}
              title={key === "used" ? "Used" : "Unused"}
              key={key}
            >
              <Table
                striped
                bordered
                responsive
              >
                <thead>
                  <tr>
                    <th>Authorization Code</th>
                    <th>Created On</th>
                    {!unUsedTab && (
                      <>
                        <th>Branch Assignment</th>
                        <th>Branch Location Assignment</th>
                      </>
                    )}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{item.device_code}</td>
                        <td>
                          {formatDate(
                            item.timestamp,
                            dateFormats.shortDateTime24HrSecs
                          )}
                        </td>
                        {!unUsedTab && (
                          <>
                            <td>{configs.branches[item.branch_id].name}</td>
                            <td>
                              {configs.branches[
                                item.branch_location_id
                              ]?.name?.replace("-> ", "") || "None"}
                            </td>
                          </>
                        )}
                        {unUsedTab &&
                          hasEmpPermission(
                            EmpPermissions.MANAGE_ATTENDANCE
                          ) && (
                            <td>
                              <Button
                                variant={"outline-primary"}
                                onClick={() => assignToBranch(item)}
                              >
                                Assign to a Branch
                              </Button>
                            </td>
                          )}
                        {!unUsedTab &&
                          hasEmpPermission(
                            EmpPermissions.MANAGE_ATTENDANCE
                          ) && (
                            <td>
                              <Button
                                variant={"outline-danger"}
                                onClick={() => {
                                  showAppModal({
                                    title: `Unassign Device from Branch`,
                                    component: (
                                      <p>
                                        Are you sure you want to unassign this
                                        device from the branch?
                                      </p>
                                    ),
                                    truthyFunction: () => {
                                      patchRequest(
                                        `/attendance/devices/${item.id}`,
                                        loaderCallback,
                                        (res) => {
                                          setDevices(res.devices)
                                        },
                                        {},
                                        false,
                                        false
                                      )
                                    },
                                  })
                                }}
                              >
                                <FaTrashAlt className={"me-2"} /> Unassign from
                                Branch
                              </Button>
                            </td>
                          )}
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Tab>
          )
        })}
      </Tabs>
    </div>
  )
}

export default AttendanceDevicesTab
